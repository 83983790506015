<template>
  <div class="analysisDetails-page">
    <div class="main">
      <div class="top">
        <el-button class="return-button" @click="$router.back()"></el-button>
        <el-button class="kr-normal-button">智能分析</el-button>
        <el-switch style="display: block" v-model="isWorkMode" active-color="#00ffbf" inactive-color="#ff4949" active-text="工作模式" inactive-text="浏览模式"></el-switch>
      </div>
      <div class="bottom">
        <div class="left">
          <div class="son-header">
            <i class="el-icon-picture-outline"></i>
            图片信息
          </div>
          <div class="img-tabs">
            <el-tabs v-model="typeActive" @tab-click="handleClick">
              <el-tab-pane label="缺陷" name="PHOTO_STATUS_DEFECT">
                <ul class="photo-list">
                  <li :class="currentIndex == index ? 'li-actived' : ''" v-for="(defectphoto, index) in defectPhoto" :key="index" @click="getPhotoDetails(defectPhoto, index)">
                    <i class="el-icon-picture" style="margin-right:1vh"></i>
                    {{ defectphoto.photoName }}
                  </li>
                </ul>
              </el-tab-pane>
              <el-tab-pane label="正常" name="PHOTO_STATUS_NORMAL">
                <ul class="photo-list">
                  <li :class="currentIndex == index ? 'li-actived' : ''" v-for="(normalphoto, index) in normalPhoto" :key="index" @click="getPhotoDetails(normalPhoto, index)">
                    <i class="el-icon-picture" style="margin-right:1vh"></i>
                    {{ normalphoto.photoName }}
                  </li>
                </ul>
              </el-tab-pane>
              <el-tab-pane label="误检" name="PHOTO_STATUS_MISTAKE">
                <ul class="photo-list">
                  <li :class="currentIndex == index ? 'li-actived' : ''" v-for="(mistakephoto, index) in mistakePhoto" :key="index" @click="getPhotoDetails(mistakePhoto, index)">
                    <i class="el-icon-picture" style="margin-right:1vh"></i>
                    {{ mistakephoto.photoName }}
                  </li>
                </ul>
              </el-tab-pane>
              <el-tab-pane label="未检测" name="PHOTO_STATUS_UNCHECK">
                <ul class="photo-list">
                  <li :class="currentIndex == index ? 'li-actived' : ''" v-for="(unCheckphoto, index) in unCheckPhoto" :key="index" @click="getPhotoDetails(unCheckPhoto, index)">
                    <i class="el-icon-picture" style="margin-right:1vh"></i>
                    {{ unCheckphoto.photoName }}
                  </li>
                </ul>
              </el-tab-pane>
              <div style="position:absolute;bottom:0;display:flex;justify-content: center;align-items: center;width:100%">
                <el-pagination
                  class="pagerLi"
                  :current-page.sync="pageNumber"
                  @current-change="getPhotosByStatus()"
                  :page-size="pageSize"
                  :pager-count="5"
                  layout="prev, pager, next"
                  :total="total"
                ></el-pagination>
                <p style="font-size:1.4vh;">{{ total }}条</p>
                <p style="width:.5vh;"></p>
                <p style="font-size:1.4vh;">共{{ Math.ceil(total / pageSize) }}页</p>
              </div>
            </el-tabs>
          </div>
        </div>
        <div class="middle">
          <div class="middle-header">
            <div>任务名称：{{ $route.query.taskName }}</div>
            <div>当前图片：{{ currentPhoto.photoName }}</div>
            <div>线路名：{{ $route.query.lineName }}</div>
            <div>塔杆号：{{ currentPhoto.towerName }}</div>
          </div>
          <div class="photo-area">
            <div v-if="total == 0" style="text-align:center;padding:40vh;">
              <span>没有符合查询条件的图片</span>
            </div>
            <ui-marker
              v-else
              ref="aiPanel-editor"
              class="ai-observer"
              @vmarker:onImageLoad="imageLoadFun"
              @vmarker:onReady="readyFun"
              @vmarker:onAnnoAdded="annoAddedFun"
              @vmarker:onAnnoSelected="annoSelectedFun"
              @vmarker:onUpdated="updatedFun"
              @vmarker:onAnnoDataFullLoaded="annoDataFullLoadedFun"
              :uniqueKey="currentPhoto.photoUrl"
              :imgUrl="currentPhoto.photoUrl"
              :readOnly="!isWorkMode"
            ></ui-marker>
          </div>
        </div>
        <div class="right">
          <div class="top-button">
            <el-button class="kr-normal-button">检测</el-button>
            <el-button class="kr-normal-button">统计查看</el-button>
            <el-button class="kr-normal-button">结果筛选</el-button>
          </div>
          <div class="son-header">
            <Icon name="defect" class="icon-class" />
            缺陷信息
          </div>
          <div class="defect-msg-input">
            <el-form label-width="80px" :model="defectForm">
              <el-form-item label="导线">
                <el-select v-model="defectForm.region" placeholder="导线">
                  <!-- <el-option label="" value="shanghai"></el-option> -->
                </el-select>
              </el-form-item>
              <el-form-item label="大小号侧">
                <el-select v-model="defectForm.region" placeholder="大小号侧">
                  <!-- <el-option label="区域一" value="shanghai"></el-option> -->
                </el-select>
              </el-form-item>
              <el-form-item label="详细信息">
                <el-select v-model="defectForm.region" placeholder="详细信息">
                  <!-- <el-option label="区域一" value="shanghai"></el-option> -->
                </el-select>
              </el-form-item>
            </el-form>
          </div>
          <div class="photo-defect">
            <el-tabs v-model="phototDefect" @tab-click="handleClickDefect">
              <el-tab-pane label="图中缺陷" name="first">
                <!-- <el-checkbox-group v-model="photoDefectList">
                  <el-checkbox v-for="(defect, index) in photoAllDefectList" :key="index" :label="defect.tagName"></el-checkbox>
                </el-checkbox-group> -->
                <ul class="photo-defect-list">
                  <li v-for="(defect, index) in photoAllDefectList" :key="index">
                    {{ defect.tagName }}
                  </li>
                </ul>
              </el-tab-pane>
              <el-tab-pane label="新增缺陷" name="second" v-if="isWorkMode">
                <el-input class="normal-input" placeholder="请输入关键词" v-model="defectSearchKey" @input="defectSearch"></el-input>
                <el-radio-group v-model="newDefectName" @change="addTagToMarker()">
                  <el-radio :label="defect.defectDetail" v-for="defect in filterDefectList" :key="defect.id">{{ defect.defectDetail }}</el-radio>
                </el-radio-group>
              </el-tab-pane>
              <el-tab-pane label="自定义缺陷" name="third" v-if="isWorkMode"></el-tab-pane>
            </el-tabs>
            <div class="save-button">
              <el-button class="kr-normal-button" :disabled="!isWorkMode" @click="savePhotoDefectMarker">保 存</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 自定义缺陷表 -->
    <el-dialog :visible.sync="diyDefectTableVisible" :close-on-click-modal="false" class="my-dialog diy-dialog" width="70%" top="20vh">
      <span slot="title" class="dialog-footer">
        <el-button class="kr-normal-button" @click="addDiyDefectBtn">添加缺陷</el-button>
      </span>
      <el-table :data="diyDefectList" style="width: 100%" :height="400">
        <el-table-column type="index" label="序号" width="100"></el-table-column>
        <el-table-column prop="defectDetail" label="名称" width="200">
          <!-- <template slot-scope="scope">
            <span v-if="!scope.row.isRename">{{ scope.row.defectDetail }}</span>
            <el-input v-else class="rename-input" :ref="scope.row.id" @blur="edit(scope.row)" v-model="newDetailsName"></el-input>
          </template> -->
        </el-table-column>
        <el-table-column prop="defectLevel" label="缺陷等级" align="center" width="150">
          <template slot-scope="scope">
            <span>{{ showDefectLevel(scope.row.defectLevel) }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="creatorName" label="创建人" align="center"></el-table-column>
        <el-table-column prop="createTime" label="创建时间" align="center"></el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button class="table-btn" type="text" @click="editBtn(scope.row)"><i class="el-icon-edit" style="color:#fff;font-size:1.5vh;"></i></el-button>
            <el-button class="table-btn" type="text" @click="deleteDefect(scope.row)"><i class="el-icon-delete" style="color:#fff;font-size:1.5vh;"></i></el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
    <!-- 新增自定义缺陷弹框 -->
    <el-dialog class="my-dialog small-dialog" :close-on-click-modal="false" title="新增自定义缺陷" :visible.sync="addDiyDefectDialogVisible" @close="initdiyDefectForm" width="20%" top="30vh">
      <el-form :model="diyDefectForm" :rules="diyDefectFormRules" ref="diyDefectForm">
        <el-form-item label="缺陷名称" prop="detail">
          <el-input v-model="diyDefectForm.defectDetail"></el-input>
        </el-form-item>
        <el-form-item label="缺陷等级" prop="level">
          <el-select v-model="diyDefectForm.defectLevel" placeholder="请选择">
            <el-option v-for="(item, index) in defectLevelType" :key="index" :label="item.value" :value="item.key"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button class="cancel-button" @click="addDiyDefectDialogVisible = false">取 消</el-button>
        <el-button class="save-button" @click="saveDiyDefect('diyDefectForm')">保 存</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import Icon from "@/components/Icon.vue";
import { AIMarker } from "vue-picture-bd-marker";
import {
  getAllDefect,
  filterDefect,
  getSelectPhotos,
  getselectSinglePhoto,
  postDefectDetailsByName,
  saveDefect,
  getDefectLevels,
  updateDefect,
  deleteUserDefect
} from "@/http/analysisDetailsApi.js";
// import moment from "moment";
export default {
  name: "analysisDetails",
  components: {
    Icon,
    "ui-marker": AIMarker
  },
  data() {
    return {
      diyDefectList: [],
      defectLevelType: [], //缺陷等级字典
      diyDefectTableVisible: false,
      diyDefectForm: {
        defectDetail: "",
        defectLevel: ""
      },
      diyDefectFormRules: {
        defectDetail: [{ required: true, message: "缺陷名称不能为空", trigger: "blur" }],
        defectLevel: [{ required: true, message: "缺陷等级不能为空", trigger: "blur" }]
      },
      oldDetailsName: "",
      newDetailsName: "",
      addDiyDefectDialogVisible: false,
      isAddDefect: true,
      isWorkMode: false,
      errandId: "",
      typeActive: "PHOTO_STATUS_DEFECT",
      phototDefect: "first",
      defectForm: {},
      currentIndex: 0,
      currentPhoto: "", // 当前图片
      currentPhotoLine: "", //当前图片线路名
      photoDefectList: [], //图中缺陷勾选
      photoAllDefectList: [], //图中缺陷列表
      allDefectList: [], //所有缺陷列表
      filterDefectList: [], // 过滤得到的所有缺陷集合
      defectPhoto: [], //缺陷图列表
      normalPhoto: [], //正常图列表
      mistakePhoto: [], //误检图列表
      unCheckPhoto: [], //未检测图列表
      allMarker: [], //当前图片上的所有缺陷，保存时传给后台的
      newDefectName: "",
      isUpdate: false, //是否有添加和移动标记框
      selectedUUID: "", //选中的标签的uuid
      pageNumber: 1,
      pageSize: 13,
      total: 0,
      defectSearchKey: ""
    };
  },
  methods: {

    // 当控件准备完成后回调，参数为uniqueKey
    readyFun(uniqueKey) {
      console.log("1.当控件准备完成", uniqueKey);
    },
    // 图片加载完成后回调，不包含data:image格式。回调参数,data={rawW,rawH,currentW,currentH}
    imageLoadFun(data) {
      console.log("2.图片加载完成onImageLoad", data);
    },
    // 当画完一个标注框时回调，参数为data【标注数据】, uniqueKey
    annoAddedFun(data, uniqueKey) {
      console.log("3.当画完一个标注框时回调", data, uniqueKey);
      this.selectedUUID = data.uuid;
    },
    // 当标注框位置或者标框属性发生改动时回调，参数为data【标注数据】, uniqueKey
    updatedFun(data, uniqueKey) {
      // this.allMarker = this.getAllMarker();
      console.log("4.当标注框位置或者标框属性发生改动时回调", data, uniqueKey);
      this.allMarker.forEach(item => {
        if (item.uuid == this.selectedUUID) {
          item.tagName = this.newDefectName;
        }
      });
    },
    // 当选中图片上的标注框时回调，参数为data【标注数据】, uniqueKey
    annoSelectedFun(data, uniqueKey) {
      this.isUpdate = true;
      // 移动以前的画框，获取位置不会改变，此处修正位置
      this.allMarker = this.getAllMarker();
      var uuid = uniqueKey.getAttribute("data-uuid");
      this.selectedUUID = uuid;
      console.log("5.当选中图片上的标注框时回调", data, uniqueKey, this.selectedUUID);
      var top = uniqueKey.style.top;
      var left = uniqueKey.style.left;
      var width = uniqueKey.style.width;
      var height = uniqueKey.style.height;
      var arr = [left, width, top, height];
      this.allMarker.forEach(item => {
        if (item.uuid == uuid) {
          arr[1] = (Number(arr[0].split("%")[0]) + Number(arr[1].split("%")[0])).toFixed(2) + "%";
          arr[3] = (Number(arr[2].split("%")[0]) + Number(arr[3].split("%")[0])).toFixed(2) + "%";
          item.position = {
            x: arr[0],
            x1: arr[1],
            y: arr[2],
            y1: arr[3]
          };
        }
      });
    },
    // 当标注框主动渲染数据后时回调，参数为uniqueKey
    annoDataFullLoadedFun(uniqueKey) {
      console.log("当标注框主动渲染数据后时回调", uniqueKey);
      var _this = this;
      var del = document.getElementsByClassName("g-image-op-del");
      del.forEach(dom => {
        dom.addEventListener("click", function() {
          if (_this.isWorkMode) {
            _this.savePhotoDefectMarker();
          }
        });
      });
      // 取消默认的中
      if (document.querySelector(".annotation.selected")) {
        var selectedAnno = document.querySelector(".annotation.selected");
        var dot = document.querySelectorAll(".annotation.selected>.resize-dot");
        dot.forEach(item => {
          var classStr = item.getAttribute("class");
          if (classStr.indexOf("hidden") == -1) {
            item.setAttribute("class", `${classStr} hidden`);
          }
        });
        selectedAnno.setAttribute("class", "annotation");
      }
    },
    // 点击类型时调用
    handleClick(tab) {
      this.pageNumber = 1;
      if (this.isUpdate) {
        this.$confirm("标记已做更改，尚未保存，是否保存?", "提示", {
          confirmButtonText: "保存",
          cancelButtonText: "不保存",
          type: "warning"
        })
          .then(() => {
            this.savePhotoDefectMarker();
            this.isUpdate = false;
          })
          .catch(() => {
            this.getPhotosByStatus();
            this.isUpdate = false;
          });
      } else {
        this.getPhotosByStatus();
      }
    },
    handleClickDefect(tab) {
      if (tab.name == "third") {
        // console.log("弹出自定义缺陷表格");
        this.diyDefectTableVisible = true;
        this.getDiyDefectDetailsList();
      } else if (tab.name == "second") {
        this.getAllDefectList();
      }
    },
    initdiyDefectForm() {
      this.diyDefectForm = {
        defectDetail: "",
        defectLevel: ""
      };
    },
    // 获取所有缺陷
    getAllDefectList() {
      getAllDefect().then(res => {
        this.allDefectList = res.data;
        this.filterDefectList = this.allDefectList;
      });
    },
    // 获取自定义缺陷
    getDiyDefectDetailsList() {
      filterDefect({ pageNumber: 1, pageSize: 100, selectFlag: 2 }).then(res => {
        // console.log(res);
        res.rows.forEach((item, index) => {
          this.$set(res.rows[index], "isRename", false);
        });
        this.diyDefectList = res.rows;
      });
    },
    // 获取自定义缺陷等级
    getDefectLevelsList() {
      getDefectLevels().then(res => {
        if (res.code === "000000") {
          this.defectLevelType = res.data;
        }
      });
    },
    /*
     * @Date 2022/02/11 18:40:25
     * 缺陷等级展示
     */
    showDefectLevel(defectLevel) {
      return this.defectLevelType.find(l => {
        if (l.key.indexOf(defectLevel) > -1) {
          return l;
        }
      }).value;
    },
    // 添加自定义缺陷按钮
    addDiyDefectBtn() {
      // this.diyDefectTableVisible = false;
      this.addDiyDefectDialogVisible = true;
    },
    // 保存自定义缺陷按钮
    saveDiyDefect(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          if (this.isAddDefect) {
            saveDefect(this.diyDefectForm).then(res => {
              if (res.code === "000000") {
                this.addDiyDefectDialogVisible = false;
                this.$message({
                  type: "success",
                  message: res.data
                });
                this.getDiyDefectDetailsList();
                // this.diyDefectTableVisible = true;
              } else {
                this.$message({
                  type: "error",
                  message: res.data
                });
              }
            });
          } else {
            updateDefect(this.diyDefectForm).then(res => {
              if (res.code === "000000") {
                this.addDiyDefectDialogVisible = false;
                this.$message({
                  type: "success",
                  message: res.data
                });
                this.getDiyDefectDetailsList();
              } else {
                this.$message({
                  type: "error",
                  message: res.data
                });
              }
            });
          }
        }
      });
    },
    //
    editBtn(row) {
      // this.diyDefectList.forEach(item => {
      //   item.isRename = false;
      // });
      // row.isRename = true;
      // this.oldDetailsName = row.detail;
      // this.newDetailsName = row.detail;
      // this.$nextTick(() => {
      //   this.$refs[row.id].focus();
      // });
      this.diyDefectForm.id = row.id;
      this.diyDefectForm.defectDetail = row.defectDetail;
      this.diyDefectForm.defectLevel = row.defectLevel;
      this.addDiyDefectDialogVisible = true;
      this.isAddDefect = false;
    },
    // 编辑自定义缺陷按钮
    edit(row) {
      let params = {
        afterUpdateDetail: this.newDetailsName,
        preUpdateDetail: this.oldDetailsName,
        defectId: row.id
      };
      if (params.afterUpdateDetail === params.preUpdateDetail) {
        row.isRename = false;
        return;
      }
      updateDefect(params).then(res => {
        if (res.meta.code == 200) {
          this.$message({
            type: "success",
            message: res.meta.msg
          });
          row.isRename = false;
          this.getDiyDefectDetailsList();
        } else {
          this.$message({
            type: "error",
            message: res.data
          });
        }
      });
      row.isRename = false;
    },
    // 删除自定义缺陷
    deleteDefect(row) {
      this.$confirm("该操作将删除自定义缺陷，是否继续?", "提示", {
        confirmButtonText: "确 定",
        cancelButtonText: "取 消",
        type: "warning"
      })
        .then(() => {
          deleteUserDefect({ id: row.id }).then(res => {
            if (res.code === "000000") {
              this.$message({
                type: "success",
                message: res.data
              });
              this.getDiyDefectDetailsList();
            } else {
              this.$message({
                type: "error",
                message: res.data
              });
            }
          });
        })
        .catch(() => {
          console.log("取消删除");
        });
    },
    // 根据状态获取图片
    getPhotosByStatus() {
      let params = {
        errandId: this.$route.query.errandId,
        lineId: this.$route.query.lineId,
        photoStatus: this.typeActive,
        pageNumber: this.pageNumber,
        pageSize: this.pageSize
      };
      getSelectPhotos(params).then(res => {
        // console.log(res);
        if (res.total != 0) {
          switch (this.typeActive) {
            case "PHOTO_STATUS_DEFECT":
              this.defectPhoto = res.rows;
              this.total = res.total;
              this.getPhotoDetails(this.defectPhoto, 0);
              break;
            case "PHOTO_STATUS_NORMAL":
              this.normalPhoto = res.rows;
              this.total = res.total;
              this.getPhotoDetails(this.normalPhoto, 0);
              break;
            case "PHOTO_STATUS_MISTAKE":
              this.mistakePhoto = res.rows;
              this.total = res.total;
              this.getPhotoDetails(this.mistakePhoto, 0);
              break;
            case "PHOTO_STATUS_UNCHECK":
              this.unCheckPhoto = res.rows;
              this.total = res.total;
              this.getPhotoDetails(this.unCheckPhoto, 0);
              break;
            default:
              break;
          }
        } else {
          this.unCheckPhoto = [];
          this.defectPhoto = [];
          this.normalPhoto = [];
          this.mistakePhoto = [];
          this.total = 0;
        }
      });
    },
    // 获取照片信息，地址，名称，缺陷信息
    getPhotoDetails(photoList, index) {
      this.currentIndex = index;
      let params = {
        id: photoList[index].id
      };
      getselectSinglePhoto(params).then(res => {
        if (res.code === "000000") {
          this.currentPhoto = res.data;
          this.defectRegionMapTurnToVueMarker(this.currentPhoto.defectRegionList);
        }
        // // 取出缺陷的名称，显示为图中缺陷列表
        // let Keys = Object.keys(res.defectRegionMap);
        // this.photoAllDefectList = [];
        // Keys.forEach((item, index) => {
        //   let obj = {
        //     detail: item,
        //     id: index
        //   };
        //   this.photoAllDefectList.push(obj);
        // });
      });
    },
    // 把后端返回的缺陷标记格式化为组件需要的格式
    defectRegionMapTurnToVueMarker(defectRegionList) {
      // let Keys = Object.keys(defectRegionList);
      this.photoAllDefectList = [];
      defectRegionList.forEach((item, index) => {
        let initCheckItem = {
          id: item.defectId,
          tag: index,
          tagName: item.defectDetail,
          position: {
            x: item.leftTopX,
            x1: item.rightBottomX,
            y: item.leftTopY,
            y1: item.rightBottomY
          }
          // level: "02",
          // creator: "system",
        };
        this.photoAllDefectList.push(initCheckItem);
      });
      // 渲染到图片上
      this.renderMarker(this.photoAllDefectList);
    },
    // 把组件返回的数据格式化为后台需要的格式
    vueMarkerTurnToDefectRegionMap(markerArr) {
      // let markerObj = {};
      // markerArr.forEach(item => {
      //   markerObj[item.tagName] = [item.position.x, item.position.x1, item.position.y, item.position.y1];
      // });

      return markerArr.map(item => {
        let defect = this.filterDefectList.find(d => {
          if (item.tagName.indexOf(d.defectDetail) > -1) {
            return d;
          }
        });

        return {
          defectId: defect.id,
          leftTopX: item.position.x,
          rightBottomX: item.position.x1,
          leftTopY: item.position.y,
          rightBottomY: item.position.y1
        };
      });

      // return markerObj;
    },
    // 渲染缺陷到图片上
    renderMarker(defectArr) {
      // var arr = [
      //   {
      //     tag: "id04",
      //     tagName: "蜜蜂",
      //     position: {
      //       x: "41.026%",
      //       x1: "53.790000000000006%",
      //       y: "22.678%",
      //       y1: "40.496%"
      //     },
      //     uuid: "5559A20B25712D9D"
      //   }
      // ]
      this.clearMarker(); //渲染数据之前先清除
      this.$refs["aiPanel-editor"].getMarker().renderData(defectArr); //渲染缺陷，接收一个数组
    },
    // 清除缺陷数据
    clearMarker() {
      this.$refs["aiPanel-editor"].getMarker().clearData();
    },
    // 给选中的标记框打上标签
    addTagToMarker() {
      if (document.querySelector(".annotation.selected")) {
        this.$refs["aiPanel-editor"].setTag({
          tagName: this.newDefectName,
          tag: "0x0001"
        });
      } else {
        this.$message.warning("请先点击选择一个标记");
      }
    },
    // 获取当前图片上的所有标签信息
    getAllMarker() {
      let allMarker = this.$refs["aiPanel-editor"].getMarker().getData();
      return allMarker;
    },

    // 保存按钮
    savePhotoDefectMarker() {
      // 获取当前组件所有缺陷标记
      this.allMarker = this.getAllMarker();
      // 将组件数据格式转化为后端需要的数据格式
      var defectList = this.vueMarkerTurnToDefectRegionMap(this.allMarker);

      let params = {
        id: this.currentPhoto.id,
        defectList: defectList
      };
      var isNoneName = false; //是否没选标签
      this.allMarker.forEach(item => {
        if (item.tagName == "请选择或添加新标签") {
          console.log("isNoneName", isNoneName);
          isNoneName = true;
        }
      });
      if (isNoneName) {
        this.$message.warning("请打上标签后点击保存");
      } else {
        postDefectDetailsByName(params).then(res => {
          if (res.code === "000000") {
            this.$message({
              type: "success",
              message: "标签保存成功"
            });
            this.isUpdate = false;
            this.getPhotosByStatus();
            // switch (this.typeActive) {
            //   case "PHOTO_STATUS_DEFECT":
            //     this.getPhotoDetails(this.defectPhoto, this.currentIndex);
            //     break;
            //   case "PHOTO_STATUS_NORMAL":
            //     this.getPhotoDetails(this.normalPhoto, this.currentIndex);
            //     break;
            //   case "PHOTO_STATUS_MISTAKE":
            //     this.getPhotoDetails(this.mistakePhoto, this.currentIndex);
            //     break;
            //   case "PHOTO_STATUS_UNCHECK":
            //     this.getPhotoDetails(this.unCheckPhoto, this.currentIndex);
            //     break;
            //   default:
            //     break;
            // }
          }
        });
      }
    },
    // 缺陷模糊查询
    defectSearch(keyWord) {
      if (!keyWord) {
        this.filterDefectList = this.allDefectList;
      } else {
        this.filterDefectList = this.allDefectList.filter(d => {
          if (d.defectDetail.toUpperCase().indexOf(keyWord.toUpperCase()) > -1) {
            return d;
          }
        });
      }
    }
  },
  mounted() {
    this.errandId = this.$route.query.errandId;
    this.getAllDefectList();
    this.getDefectLevelsList();
    this.$nextTick(() => {
      this.handleClick();
    });
  }
};
</script>
<style lang="scss" scoped>
.analysisDetails-page {
  height: calc(100% - 5vh);
  padding: 0 1vw;
  color: #fff;
  .main {
    height: 100%;
    display: flex;
    flex-direction: column;
    .top {
      height: 8vh;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .bottom {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 100%;
      .left {
        width: 13.6vw;
        height: 100%;
        margin-right: 1vw;
      }
      .middle {
        flex-grow: 1;
        height: 100%;
      }
      .right {
        width: 13.6vw;
        height: 100%;
        margin-left: 1vw;
        .top-button {
          margin-bottom: 1vh;
          .el-button {
            padding: 1.2vh 1vh;
          }
        }
        .photo-defect {
          padding-bottom: 1vh;
          .photo-defect-list {
            padding: 0;
            margin: 0;
            li {
              list-style: none;
              height: 4vh;
              line-height: 4vh;
              padding-left: 1vh;
            }
          }
          .save-button {
            text-align: center;
          }
        }
      }
    }
    // align-items: center;
  }
}
.li-actived {
  color: #e4ff00;
  border-left: 2px solid #e4ff00;
  color: #e4ff00;
  background: linear-gradient(90deg, rgba(228, 255, 0, 0.5), rgba(228, 255, 0, 0));
}
</style>
